import 'regenerator-runtime/runtime';
import Head from 'next/head';
import {
    ChakraProvider,
    extendTheme,
    theme as baseTheme,
} from '@chakra-ui/react';
import {theme as proTheme} from '@chakra-ui/pro-theme';
import {registerLocale} from 'react-datepicker';
// Supports weights 300-900
import '@fontsource-variable/figtree';
import {NhostClient, NhostProvider} from '@nhost/nextjs';
import de from 'date-fns/locale/de';
import 'react-datepicker/dist/react-datepicker.css';
import '@/components/date-time-input.css';
import PlausibleProvider from 'next-plausible';

registerLocale('de', de);

const nhost = new NhostClient({
    subdomain: process.env.NEXT_PUBLIC_NHOST_SUBDOMAIN,
    region: process.env.NEXT_PUBLIC_NHOST_REGION,
});

const MyApp = ({Component, pageProps}) => {
    const isLocalhost =
        typeof window === 'object' && window.location.host === 'localhost';
    const myTheme = extendTheme(
        {
            colors: {
                ...baseTheme.colors,
                brand: {
                    DEFAULT: '#0A6B32',
                    50: '#3FED87',
                    100: '#2DEB7B',
                    200: '#14DB66',
                    300: '#11B655',
                    400: '#0D9043',
                    500: '#0A6B32',
                    600: '#05381A',
                    700: '#000402',
                    800: '#000000',
                    900: '#000000',
                    950: '#000000',
                },
                yellow: {
                    DEFAULT: '#FECB00',
                    50: '#FFF0B7',
                    100: '#FFECA2',
                    200: '#FFE479',
                    300: '#FFDC51',
                    400: '#FFD428',
                    500: '#FECB00',
                    600: '#C69E00',
                    700: '#8E7100',
                    800: '#564400',
                    900: '#1E1800',
                    950: '#020100',
                },
            },
        },
        proTheme,
        {
            fonts: {
                heading: "'Figtree Variable', sans-serif",
                body: "'Figtree Variable', sans-serif",
            },
        }
    );
    return (
        <>
            {/* <HighlightInit
                excludedHostnames={['localhost']}
                projectId={process.env.NEXT_PUBLIC_HIGHLIGHT_PROJECT_ID}
                tracingOrigins
                networkRecording={{
                    enabled: true,
                    recordHeadersAndBody: true,
                }}
            /> */}
            <Head>
                <link
                    rel="apple-touch-icon"
                    sizes="152x152"
                    href="/apple-touch-icon.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href="/favicon-32x32.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href="/favicon-16x16.png"
                />
                <link rel="manifest" href="/site.webmanifest" />
                <link
                    rel="mask-icon"
                    href="/safari-pinned-tab.svg"
                    color="#5bbad5"
                />
                <meta name="msapplication-TileColor" content="#da532c" />
                <meta name="theme-color" content="#ffffff" />
            </Head>

            <PlausibleProvider domain="preisrechner.drhartl.at">
                <NhostProvider nhost={nhost} initial={pageProps.nhostSession}>
                    <ChakraProvider theme={myTheme} portalZIndex={40}>
                        <Component {...pageProps} />
                    </ChakraProvider>
                </NhostProvider>
            </PlausibleProvider>
        </>
    );
};

export default MyApp;
